.c-header {
	padding-top: 22px;
	padding-bottom: 22px;
	background-color: lightgrey; // Temp.
}

.c-header .c-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.c-header__logo {
	flex: 1 0 auto;
}

.c-header__logo .logo {
	width: auto;
	height: 44px;
}

@media ( min-width: 900px ) {
	.c-header__logo .logo {
		height: 100px;
	}
}


.c-header__contact {
	display: none;
	align-self: center;
	color: hsl(0, 0%, 100%);
}


@media ( min-width: 900px ) {
	.c-header__contact {
		display: block;
		margin-right: 37px;
	}
}

.c-header__contact a {
	color: hsl(0, 0%, 100%);
}

/* Header: Menu
   ========================================================================== */

.c-header__menu {
	align-self: center;
	color: hsl(0, 0%, 100%);
	fill: currentColor;
	line-height: 26px;
}

.c-header__menu .text {
	display: none;
	margin-right: 0.375rem;
}

@media ( min-width: 900px ) {
	.c-header__menu .text {
		display: inline;
	}
}

.c-header__menu a {
	color: hsl(0, 0%, 100%);
	fill: currentColor;
}

.c-header__menu a:hover,
.c-header__menu a:focus {
	text-decoration: none;
}

.c-header__menu .icon--menu {
	display: inline;
	height: 26px;
	width: 30px;
	vertical-align: text-bottom;
}

/* Header: Overlay
   1. Force element to be full width of screen.
   ========================================================================== */
.has-hero .c-header {
	position: absolute;
	width: 100%; // [1]
	background-color: rgba(25,25,25,0.65);
}

/* Header Small
   ========================================================================== */

.c-header--small {
	padding-top: 52px;
}

.c-header--small .c-header__logo {
	text-align: center;
}
