/* Testimonial
 * 1. Adds column gaps to slider when more than one item is present.
   ========================================================================== */
.c-testimonial {
	position: relative;
	margin-right: 13px; /* [1] */
	margin-left: 13px; /* [1] */
}

.c-testimonial__aside {
	margin-bottom: 26px;
}

.c-testimonial .c-icon--overlay-quote {
	position: absolute;
	right: 0;
	width: 58px;
	height: 94px;
	color: #3c1f51;
}

@media ( min-width: 900px ) {
	.c-testimonial .c-icon--overlay-quote {
		height: 182px;
		width: 110px;
	}
}

.c-testimonial__image {
	border-radius: 99999px;
}

.c-testimonial__quote {
	margin-right: 0;
	margin-left: 0;
}

@media ( min-width: 900px ) {
	.c-testimonial__quote {
		margin-right: 100px;
	}
}

.c-testimonial__author {
	margin-top: 16px;
	font-size: 1rem;
	font-weight: $font-weight-normal;
	color: #F4BB3B;
	letter-spacing: 0;
	line-height: 1.625rem;
}

.c-testimonial__author [itemprop="author"] {
	font-weight: $font-weight-bold;
}

.t-dark .c-testimonial .c-icon--overlay-quote {
	color: #fff;
}
