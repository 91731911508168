.c-intro {
	padding: 13px;
	border-top-right-radius: 6px;
	border-top-left-radius: 6px;
	background-color: #fff;
}

@media ( min-width: 900px ) {
	.c-intro {
		display: flex;
		flex-direction: row;
		padding: 39px;
	}
}

@media ( min-width: 900px ) {
	.c-intro > *:not(:first-child) {
		margin-left: 39px;
	}
}

/* Intro: Content
   ========================================================================== */
.c-intro__content {
	flex: 1 1 100%;
	margin-bottom: 26px;
}

/* Intro: Aside
   ========================================================================== */
.c-intro__aside {
	flex: 1 1 100%;
	position: relative;
	align-self: start;
}

.c-intro__aside .c-icon {
	position: absolute;
	top: 8px;
	right: 8px;
	z-index: $z-index-1;
}

@media ( min-width: 900px ) {
	.c-intro__aside .c-icon {
		top: 16px;
		right: 16px;
	}
}

.c-intro__aside .c-quote--title {
	position: absolute;
	top: 8px;
	right: 8px;
	left: 8px;
	bottom: 8px;
	z-index: $z-index-2;
	font-size: 1.75rem;
	color: hsl(0, 0%, 100%);
	letter-spacing: -0.2px;
	line-height: 1.1;
	text-shadow: 0 0 26px rgba(0,0,0,0.75);
}

@media ( min-width: 600px ) {
	.c-intro__aside .c-quote--title {
		position: absolute;
		top: 16px;
		right: 35%;
		left: 10%;
		bottom: 16px;
		font-size: 2.5rem;
		line-height: 1.0270;
		text-align: left;
	}

	.c-intro__aside .c-quote--text-right {
		right: 10%;
		left: 30%;
	}
}
/* Intro: Disclosure
   ========================================================================== */
.c-intro__disclosure {
	flex: 1 1 100%;
}

.c-intro__disclosure .c-icon--intro {
	margin-bottom: 13px;
}

/* Intro: Header
   ========================================================================== */

.c-intro__header {
	margin-bottom: 26px;
	text-align: center;
}

@media ( min-width: 900px ) {
	.c-intro__header {
		flex: 1 1 100%; // [1]
		margin-bottom: 0;
	}
}

/* Intro: List
   1. Make all elements equal width.
   ========================================================================== */
.c-intro__list {
	margin-top: 26px;
	display: flex;
	flex-direction: row;
	text-align: center;
	font-size: 0.875rem;
	line-height: 1.1428;
}

@media ( min-width: 900px ) {
	.c-intro__list {
		padding-left: 26px;
		flex-direction: column;
		flex: 1 1 100%; // [1]
		text-align: left;
	}
}

@media ( min-width: 1200px ) {
	.c-intro__list {
		padding-left: 52px;
	}
}

.c-intro__list .o-list__item {
	flex: 1 1 100%; // [1]

}

@media ( min-width: 900px ) {
	.c-intro__list .o-list__item {
		display: flex;
		align-items: center;
	}
}

.c-intro__list .c-icon {
	display: block;
	margin-right: auto;
	margin-left: auto;
}

@media ( min-width: 900px ) {
	.c-intro__list .c-icon {
		display: inline-block;
		margin-right: 16px;
		margin-left: initial;
	}
}

/* Intro: Bullets
   ========================================================================== */
.c-intro__bullet {
	margin-top: 26px;
}

@media ( min-width: 900px ) {
	.c-intro__bullet {
		flex: 1 1 100%; // [1]
	}
}

@media ( min-width: 900px ) {
	.c-intro__bullet .o-list__item {
		padding-left: 26px;
		text-indent: -21px;
	}
}

.c-intro__bullet .c-icon--bullet {
	margin-right: 10px;

}


.excellence {
	margin-top: 13px;
	text-align: right;

	img {
		max-width:180px;
	}
}

.c-intro__address {
	padding-left: 26px;
	text-indent: -21px;
}

.c-intro__address .c-icon--map-marker {
	margin-right: 8px;
}

/* Intro: Single
   ========================================================================== */
.c-intro--single {
	flex-direction: column;
	padding-top: 26px;
	padding-bottom: 26px;
}

@media ( min-width: 900px ) {
	.c-intro--single {
		padding-top: 52px;
		padding-right: 104px;
		padding-left: 104px;
	}
}

@media ( min-width: 1200px ) {
	.c-intro--single {
		padding-top: 78px;
		padding-right: 156px;
		padding-left: 156px;
	}
}

.c-intro--single .c-title--primary {
	margin-bottom: 13px;
}

.c-intro--single .c-title--secondary {
	font-weight: bold;
	color: #272626;
}

.page-thank-you .c-intro--single .c-title--secondary {
	margin-top: 52px;
}

@media ( min-width: 900px ) {
	.c-intro--single .c-title--secondary {
		font-size: 1.25rem;
	}
}

.c-intro--single .tel,
.c-intro--single .link {
	display: block;
}

.c-intro--single .c-icon--phone,
.c-intro--single .c-social-media a {
	color: var(--color-canary);
}

.c-intro--single .c-social-media .o-list__item {
	margin-right: 6.5px;
	margin-left: 6.5px;
}

@media ( min-width: 900px ) {
	.c-intro--single > *:not(:first-child) {
		margin-left: 0;
	}
}

.c-intro .c-adviser {
	margin-top: 26px;
	text-align: center;
}

@media ( min-width: 900px ) {
	.c-intro .c-adviser {
		position: absolute;
		right: -20px;
		bottom: -90px;
	}
}
