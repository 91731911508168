.c-content {
	padding-top: 13px;
	padding-right: 13px;
	padding-bottom: 13px;
	padding-left: 13px;
}

@media ( min-width: 900px ) {
	.c-content {
		display: grid;
		grid-template-columns: 0.5fr 0.5fr;
		grid-gap: 26px;
		padding-top: 39px;
		padding-right: 0;
		padding-bottom: 39px;
		padding-left: 0;
	}

	.c-content .c-content__article {
		grid-column: 1;
		grid-row: 1;
	}

	.c-content .c-content__aside {
		grid-column: 2;
		grid-row: 1;
	}

}

@media ( min-width: 1200px ) {
	.c-content {
		grid-template-columns: 0.6666fr 0.3333fr;
	}

	.c-content::after {
		content: '';
		position: absolute;
		left: -123px;
		display: block;
		width: 82px;
		height: 356px;
		background-image: url( '/dist/images/side-pattern.png' );
		background-repeat: no-repeat;
	}

}

.c-content__article .c-title--secondary {
	font-size: 1.125rem;
	font-weight: $font-weight-normal;
	line-height: 1.44;
}

/* IE FIX */
@media ( min-width: 900px ) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.c-content .c-content__article,
	.c-content .c-content__aside {
		margin-right: 13px;
		margin-left: 13px;
	}
}

@media ( min-width: 1200px ) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.c-content .c-content__article,
	.c-content .c-content__aside {
		margin-right: 19.5px;
		margin-left: 19.5px;
	}
}

/* Content Header
   ========================================================================== */

.c-content--header {
	position: relative;
	padding-top: 26px;
	padding-bottom: 26px;
	border-top: $border-width-thin solid hsl(0, 0%, 89%)
}

@media ( min-width: 900px ) {
	.c-content--header {
		padding-top: 52px;
		padding-bottom: 52px;
	}
}

.c-content--header::before {
	content: '';
	position: absolute;
	top: 0;
	left: 50%;
	left: calc( 50% - 2pt );
	width: $border-width-thick;
	height: 25px;
	background-color: #F4BB3B;
}

@media ( min-width: 900px ) {
	.c-content--header::before {
		width: 3pt;
		height: 49px;
	}
}
