.c-claims {

}

.c-claims__list {
	margin-left: auto;
	margin-right: auto;
	border-radius: 6px;
	background-color: hsl(0, 0%, 100%);

	display: grid;
  grid-template-columns: 1fr 1fr;

}

@media ( min-width: 900px ) {
	.c-claims__list {
	  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	}
}

.c-claim__bullet {
	margin: 1rem 0;
	list-style: none;
	font-size: 0.875rem;

	li {
		margin-bottom: 0.5rem;
	}

}

.c-claims__list span {
	display: block;
	width: 100%;

	color: #F4BB3B;
	font-weight: 600;
	line-height: 1;
}

.c-claim__logo {
	margin-bottom: 1rem;

	img {
		width: auto;
    height: 60px;
    display: block;
    margin: 0 auto;
	}
}

.c-claim__wrapper {
	display: flex;

	.c-card__footer {
		margin: 0;
		padding: 0;
	}

}