.c-advisor-bar {
	padding-top: 13px;
	padding-bottom: 13px;
	text-align: center;

	img {
		display: block;
		max-width: 160px;
		margin: 0 auto;
	}
}
