.c-charity {

}

.c-charity__intro {

	ul {
		margin: 0.8rem 0 0 1.25rem;
		padding: 0;
	}

}

ul.c-charity__logos {
	margin: 2rem 0 0;
	list-style: none;

	& > li {
		display: inline-block;
		margin-left: 2rem;

		&:first-child {
			margin: 0;
		}

		img {
			max-height: 60px;
			width: auto;
		}
	}

}

.c-charity--main {
	display: grid;
	grid-template-columns: repeat( 1, 1fr);
	grid-gap: 26px;
	margin-left: auto;
	margin-right: auto;
	padding: 13px;
	border-radius: 6px;
	background-color: hsl(0, 0%, 100%);
}

.c-charity .c-charity__list {
	display: grid;
	grid-template-columns: repeat( 1, 1fr);
	grid-gap: 26px;
	margin-left: auto;
	margin-right: auto;
	padding: 13px;
	border-radius: 6px;
	background-color: hsl(0, 0%, 100%);
}

.c-charity__intro ul.c-charity__list--goals {
	padding-bottom:30px;
}

.c-container.c-intro.c-intro__second-charity {
	padding-top: 0;
}

.c-charity-images {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	max-width: 1080px;

	.c-card__aside {
		flex: 0 1 100%;
		margin-bottom: 20px;
		margin-top: 20px;

		@media screen and (min-width:992px) {
			flex: 0 1 calc(50% - 20px);
		}
	}
}

@media ( min-width: 600px ) {
	.c-charity .c-charity__list {
		grid-template-columns: repeat( 2, 1fr );
		padding: 26px;
	}
}

@media ( min-width: 900px ) {
	.c-charity .c-charity__list {
		grid-template-columns: repeat( 3, 1fr );
		padding: 0 39px 39px 39px;
  }
}

.c-charity .c-card__logo {
	margin-bottom: 26px;
	max-width: 104px;
}

/* IE 11 Fix */
@media ( min-width: 600px ) and ( max-width: 899px) {
	.c-charity__list .c-card--charity:nth-child(1) {
		grid-column: 1;
		grid-row: 1;
	}
	.c-charity__list .c-card--charity:nth-child(2) {
		grid-column: 2;
		grid-row: 1;
	}
	.c-charity__list .c-card--charity:nth-child(3) {
		grid-column: 1;
		grid-row: 2;
	}
	.c-charity__list .c-card--charity:nth-child(4) {
		grid-column: 2;
		grid-row: 2;
	}
	.c-charity__list .c-card--charity:nth-child(5) {
		grid-column: 1;
		grid-row: 3;
	}
	.c-charity__list .c-card--charity:nth-child(6) {
		grid-column: 2;
		grid-row: 3;
	}
	.c-charity__list .c-card--charity:nth-child(7) {
		grid-column: 1;
		grid-row: 4;
	}
	.c-charity__list .c-card--charity:nth-child(8) {
		grid-column: 2;
		grid-row: 4;
	}
	.c-charity__list .c-card--charity:nth-child(9) {
		grid-column: 1;
		grid-row: 5;
	}
	.c-charity__list .c-card--charity:nth-child(10) {
		grid-column: 2;
		grid-row: 5;
	}
	.c-charity__list .c-card--charity:nth-child(11) {
		grid-column: 1;
		grid-row: 6;
	}
	.c-charity__list .c-card--charity:nth-child(12) {
		grid-column: 2;
		grid-row: 6;
	}
}

@media (-ms-high-contrast:none) {

	.c-charity .c-charity__list {
		padding-right: 0;
		padding-left: 0;
	}

	.c-charity .c-card--charity {
		margin-right: 13px;
		margin-bottom: 26px;
		margin-left: 13px;
	}
}

@media ( min-width: 600px ) and (-ms-high-contrast:none) {

	.c-charity .c-charity__list {
		padding-right: 13px;
		padding-left: 13px;
	}

	.c-charity .c-card--charity {
		margin-right: 13px;
		margin-bottom: 26px;
		margin-left: 13px;
	}
}

@media ( min-width: 900px ) {
	.c-charity__list .c-card--charity:nth-child(1) {
		grid-column: 1;
		grid-row: 1;
	}
	.c-charity__list .c-card--charity:nth-child(2) {
		grid-column: 2;
		grid-row: 1;
	}
	.c-charity__list .c-card--charity:nth-child(3) {
		grid-column: 3;
		grid-row: 1;
	}
	.c-charity__list .c-card--charity:nth-child(4) {
		grid-column: 1;
		grid-row: 2;
	}
	.c-charity__list .c-card--charity:nth-child(5) {
		grid-column: 2;
		grid-row: 2;
	}
	.c-charity__list .c-card--charity:nth-child(6) {
		grid-column: 3;
		grid-row: 2;
	}
	.c-charity__list .c-card--charity:nth-child(7) {
		grid-column: 1;
		grid-row: 3;
	}
	.c-charity__list .c-card--charity:nth-child(8) {
		grid-column: 2;
		grid-row: 3;
	}
	.c-charity__list .c-card--charity:nth-child(9) {
		grid-column: 3;
		grid-row: 3;
	}
	.c-charity__list .c-card--charity:nth-child(10) {
		grid-column: 1;
		grid-row: 4;
	}
	.c-charity__list .c-card--charity:nth-child(11) {
		grid-column: 2;
		grid-row: 4;
	}
	.c-charity__list .c-card--charity:nth-child(12) {
		grid-column: 3;
		grid-row: 4;
	}
}

@media ( min-width: 900px ) and (-ms-high-contrast:none) {

	.c-charity .c-charity__list {
		padding-right: 13px;
		padding-left: 13px;
	}

	.c-charity .c-card--charity {
		margin-right: 19.5px;
		margin-bottom: 39px;
		margin-left: 19.5px;
	}
}


.c-feefo-reviews{
			padding:10px 0 50px;

.feefo-reviews-awards{
	display: flex;
	flex-wrap: wrap;
	align-items:center;
	justify-content: center;

	.feefo-score-wrap{
		flex: 0 0 50%;
		text-align: center;
		max-width: 300px;
		margin-bottom: 10px;
	}

		.feefo-logo{
			display: block;
			text-align: center;
			margin-bottom: 10px;
				img{
					max-width: 200px;
					}
			}

			.feefo-score{
				display: block;
				text-align: center;
					img{
						max-width: 150px;
					}
				}
	}
	.c-gold-trusted-logo-wrap{
		text-align: center;
		flex: 0 0 50%;
		max-width: 300px;
		margin-bottom: 10px;
		.gold-trusted-logo{
			img{
				max-width: 160px;
			}
		}
	}

}
