.btn {
	color: #272626;
	display: inline-block;
	min-width: 200px;
  vertical-align: middle;
  font-size: 0.875rem;
  font-weight: $font-weight-normal;
  text-align: center;
  margin: 0;
  cursor: pointer;
  text-decoration: none;
	text-transform: uppercase;
  padding: 13px 32px;
  border: 1px solid transparent;
	border-radius: 9999px;
	transition: 0.2s;
}

.btn:hover,
.btn:focus {
	text-decoration: none;
}

.t-dark.btn:visited,
.t-dark.btn:active,
.t-dark.btn:link {
	color: hsl(0, 0%, 100%);
	text-decoration: none;
}

.btn--primary {
	color: var(--color-petrol);
	background-color: var(--color-canary);
}

.btn--primary:hover,
.btn--primary:focus {
	color: #fff;
	background-color: var(--color-petrol);
	text-decoration: none;
}

.btn--spacing {
	margin-top: 13px;
	margin-bottom: 13px;
}