/* ==========================================================================
	 /Settings #Color Variables
	 ========================================================================== */

/* Brand Colors
	 ========================================================================== */

$color-white:                     hsl(0, 0%, 100%);

$color-neutral-10:                hsl(40, 7%, 10%);
$color-neutral-25:                hsl(40, 7%, 25%);
$color-neutral-50:                hsl(40, 7%, 42%);
$color-neutral-75:                hsl(40, 7%, 67%);
$color-neutral-90:                hsl(40, 7%, 92%);
$color-neutral:                   $color-neutral-50;

$color-black:                     hsl(0, 1%, 15%);


/* Text Colors
	 ========================================================================== */

$color-text:                      $color-black;
$color-text-on-light:             $color-black;
$color-text-on-dark:              $color-white;
$color-text-light:                $color-white;
$color-text-disabled:             $color-black;

$color-link:                      $color-black;
$color-link-on-dark:              $color-white;
$color-link-visited:              $color-black;
$color-link-hover:                $color-black;

$color-text-screen:               $color-black;
$color-text-input:                $color-neutral-50;
$color-text-input-focus:          $color-text;
