/* Links Settings
 *
 * These settings should be overwritten in your setting/colour file.
	 ========================================================================== */

/**
 * Links: Defaults
 */

a {
	text-decoration: none;
	transition: background-color 0.3s cubic-bezier(0.75, 0.02, 0.5, 1);
	color: #272626;
	font-weight: bold;
}

a:hover,
a:focus {
	text-decoration: underline;
}
