.c-job-list {
	display: flex;
	grid-gap: 26px;
	margin-left: auto;
	margin-right: auto;
	border-radius: 6px;
	background-color: hsl(0, 0%, 100%);
}

@media ( min-width: 900px ) {
	.c-job-list {
		flex-flow: column;
	}
}

.c-job-list .c-card {
	position: relative;
	flex-basis: 100%;
	padding: 13px;
}

.c-job__split {
  padding-top: 0;
  padding-bottom: 0;

  display: flex;
	flex-flow: column;

  @media (min-width: 900px){
	  padding-top: 26px;
    padding-bottom: 26px;
		flex-flow: row;
	}

}


@media ( min-width: 900px ) {
	.c-job-list .c-card {
		padding: 13px 13px 26px 26px;
		flex-basis: 50%;
	}
}

.c-job-list .c-card:first-child {
	border-radius: 6px;
}

.c-job-list .c-job-list__wrapper {
  display: inline-block;
	padding-bottom: 13px;
}

@media ( min-width: 500px ) {
	.c-job-list .c-job-list__wrapper {
		padding-bottom: 13px;
	}
}

@media ( min-width: 900px ) {
	.c-job-list .c-card__aside {
		grid-column: 2;
		grid-row: 1;
		align-self: center;
		column-count: 2;
		column-gap: 36px;
	}
}

@media ( min-width: 900px ) and ( -ms-high-contrast:none ) {
	.c-card--job .c-card__aside {
		margin-left: 39px;
	}
}

.c-card--job .c-card__content {
}

@media ( min-width: 900px ) {
	.c-card--job .c-card__footer {
		position: absolute;
	  bottom: 0;
	}
}

@media ( min-width: 900px ) {
	.c-card--job .c-card__footer {
		grid-column: 1 / span 2;
		grid-row: 2;
		margin-bottom: 0;
	}
}

.c-card--job .c-title--secondary {
	font-weight: $font-weight-normal;
}

.page-careers .c-block__split .link {
	text-transform: initial;
}
