/* Card
   ========================================================================= */

.c-card {
	display: flex;
	flex-direction: column;
	background-color: hsl(0, 0%, 100%);
}

.c-card__header {
	flex: 0 0 auto;
	margin-bottom: 13px;
	font-size: 1.3125;
	line-height: 1.2857;
}

.c-card__header .c-title--primary {
	font-size: 1.25rem;
	color: var(--color-petrol);
}

.c-card__header .c-title--secondary {
	font-size: 1.25rem;
	color: var(--color-canary);
}

/*
   == */

.c-card__content {
	flex: 3 0 auto;
}

.c-card__category {
	position: absolute;
	bottom: 15%;
	padding-left: 16px;
	font-size: 1rem;
	font-weight: $font-weight-bold;
	color: var(--color-canary);
}

.c-card__footer {
	flex: 0 0 auto;
	margin-top: 13px;
}

.c-card__footer .link {
	font-size: 0.875rem;
	line-height: 1.8571;
}

.c-card__footer .link .c-icon {
	vertical-align: bottom;
}

.card__aside,
.c-card__aside {
	position: relative;
	flex-shrink: 0;
}

.c-card__aside + .c-card__header {
	margin-top: 26px;
}

@media ( min-width: 1200px ) {
	.c-card__aside + .c-card__header {
		margin-top: 15px;
	}
}

.c-card__image {
	max-width: 100%;
	height: auto;
}

.c-card--service .c-card__header::before {
	content: "";
	position: relative;
	top: -12px;
	display: block;
	width: 26px;
	height: 3pt;
	border-radius: 3pt;
	background-color: var(--color-canary);
}
/* Modifier: Insurance
   ========================================================================== */
.c-card--insurance {
	padding: 13px;
	border: $border-width-thin solid hsl(0, 0%, 88%);
	border-radius: 6px;
	background-color: hsl(0, 0%, 100%);
	box-shadow: 0 0 7px 4px hsla(0, 0%, 0%, 0.04);
	text-align: center;
}

@media ( min-width: 900px ) {
	.c-card--insurance {
		padding: 26px;
	}
}

@media ( min-width: 1200px ) {
	.c-card--insurance {
		padding: 39px;
	}
}

.c-card--insurance .c-card__header .c-icon {
	margin-bottom: 26px;
}

.c-card--insurance .c-card__footer .link {
	justify-content: center;
	color: var(--color-petrol);

	&:hover {
		color: var(--color-ocean);
	}
}


/* Modifier: Claims
   ========================================================================== */
.c-card--claim {
	margin-top: 26px;
	padding-top: 26px;
	padding-right: 13px;
	padding-bottom: 26px;
	padding-left: 13px;
	border-top: $border-width-thin solid hsl(0, 0%, 89%);
}

.c-claim__list {
	margin: 0;
	padding: 0;

	list-style: none;
}

@media ( min-width: 900px ) {
	.c-card--claim {
		padding-right: 39px;
		padding-left: 39px;
	}
}

@media ( min-width: 900px ) {
	.c-card--claim .c-card__header {
		margin-bottom: 0;
	}
}

@media ( min-width: 1200px ) {
	.c-card--claim .c-card__header {
		flex-basis: 40%;
	}
}

.c-card--claim .c-card__logo {
	margin-bottom: 26px;
	max-width: 130px;
}

.c-card--claim .c-card__aside {
	flex: 1 1 100%;
}

@media ( min-width: 900px ) {
	.c-card--claim .c-card__aside {
		align-self: flex-end;
	}
}

@media ( min-width: 1200px ) {
	.c-card--claim .c-card__aside {
		flex-basis: 30%;
	}
}

.c-card--claim .c-card__footer {
	flex: 1 1 100%;
	align-self: flex-start;
	margin-top: 0;
}

@media ( min-width: 1200px ) {
	.c-card--claim .c-card__footer {
		flex-basis: 30%;
	}
}

.c-card--claim .c-card__footer p {
	margin-top: 0;
}

.c-card--claim .c-card__button {
	margin-top: 39px;
}

@media ( max-width: 900px ) {
	.c-card--claim .c-card__header,
	.c-card--claim .c-card__aside {
		margin-bottom: 26px;
	}
}

@media ( min-width: 900px ) {
	.c-card--claim {
	}

	.c-card--claim .c-card__header {
		display: contents;
	}

	.c-card--claim h3 {
		grid-column: 1;
		grid-row: 1;
	}

	.c-card--claim div {
		grid-column: 1;
		grid-row: 2;
	}

	.c-card--claim .c-card__aside {
		grid-column: 2;
		grid-row: 2;
		align-self: flex-start;
	}

	.c-card--claim .c-card__footer {
		grid-column: 3;
		grid-row: 1 / span 2;
	}

}

@media ( min-width: 1200px ) {
	.c-card--claim {
	}

}
