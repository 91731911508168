.c-title {

}

.c-title--primary {
	font-size: 1.125rem;
	font-weight: bold;
	color: var(--color-petrol);
	line-height: 1.25;
	
}

@media ( min-width: 900px ) {
	.c-title--primary {
		font-size: 1.625rem;
		font-weight: bold;
		line-height: 1.25;
	}
}



.c-title--secondary {
	font-size: 1rem;
	font-weight: 600;
	color: var(--color-canary);
	line-height: 1.625;
}

.c-title--secondary + p {
	margin-top: 0;
}

.c-title--tertiary {

}

.c-title--tertiary + p,
p + .c-title--tertiary {
	margin-top: 13px;
}

.c-title--primary + .c-title--secondary {
	margin-top: 6.5px;
}

header + .c-title--secondary {
	margin-top: 13px;
}

header + .c-title--tertiary {
	margin-top: 26px;
}

header + p {
	margin-top: 13px;
	font-weight: bold;
}

p + .c-title--secondary {
	margin-top: 26px;
}

.c-heading--additional {
	margin-top: 13px;
}

.c-text--standard-weight {
	font-weight: 400;
}

/* Title: Super
   ========================================================================== */
.c-title--super {
	font-size: 2.375rem;
	line-height: 1.2;
	font-weight: bold;
	color: var(--color-canary);
}

@media ( min-width: 900px ) {
	.c-title--super {
		font-size: 3.75rem;
	}
}
