.c-icon {
	width: 20px;
	height: 26px;
	vertical-align: middle;
}

.c-icon--medium {
	width: 39px;
	height: 39px;
}

.c-icon--large {
	width: 78px;
	height: 78px;
}

.c-icon--x-large {
	width: 110px;
	height: 182px;
}

.c-icon--intro {
	fill: var(--color-canary);
}

.c-icon--map-marker {
	width: 13px;
	vertical-align: bottom;
	fill: var(--color-canary);
}

.c-icon--bullet {
	margin-right: 6px;
	width: 7px;
	fill: var(--color-canary);
}

.c-icon--brand-linkedin {
	width: 26px;
	height: 26px;
}

.c-icon--link-arrow-left {
	margin-right: 6.5px;
	width: 26px;
	height: 26px;
}
