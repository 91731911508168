.c-hero {
	padding-top: 52px;
	padding-bottom: 52px;
	background-color: hsl(0, 0%, 0%);
	background-image:
		linear-gradient(
			180deg,
			rgba(254, 203, 38,0) 0%,
			rgba(83, 86, 90,0.85) 100%
		),
		linear-gradient(
			45deg,
			rgba(254, 203, 38,0.5) 0%,
			rgba(83, 86, 90,0.5) 100%
		),
		url( '../images/hero/hero-home-new.jpg' );
	background-position: top center;
	background-blend-mode: multiply, soft-light, normal;
	background-size: cover;
	background-repeat: no-repeat;
	color: hsl(0, 0%, 100%);
}

// Add extra space if our header overlays the hero.
.c-header + .c-hero {
	padding-top: 146px;
}

// Forces the element below to overlap the hero element.
.c-hero--overlap {
	margin-bottom: -52px;
	padding-bottom: 104px;
}

.c-hero--overlap.c-hero--contact {
	margin-bottom: -364px;
	padding-bottom: 390px;
}

.c-hero .c-separator {
	margin-top: 26px;
	margin-bottom: 26px;
	width: 78px;
	height: $border-width-thick;
	border: none;
	border-radius: $border-width-thick;
	background-color: hsl(0, 0%, 100%);
}

/* Hero: Header
   ========================================================================== */
@media ( min-width: 900px ) {
	.c-hero__header {
		margin-top: 150px;
	}
}

@media ( min-width: 900px ) {
	.c-hero--contact .c-hero__header {
		margin-top: 91px;
	}
}

/* Hero: Title
   ========================================================================== */
.c-hero__title-image {
	display: block;
	width: 100%;
	height: auto;
}

@media ( min-width: 600px ) {
	.c-hero__title-image {
		margin-bottom: 13px;
		max-width: 450px;
	}
}

@media ( min-width: 900px ) {
	.c-hero__title-image {
		margin-bottom: 26px;
		max-width: 802px;
	}
}

@media ( min-width: 900px ) {
	.c-hero--contact .c-hero__title-image {
		max-width: 602px;
	}
}

@media ( min-width: 900px ) {
	.c-hero--restaurant-leisure .c-hero__title-image {
		max-width: 675px;
	}
}

@media ( min-width: 900px ) {
	.c-hero--private-client-services .c-hero__title-image {
		max-width: 845px;
	}
}

@media ( min-width: 900px ) {
	.c-hero--about .c-hero__title-image {
		max-width: 682px;
	}
}

@media ( min-width: 900px ) {
	.c-hero--claims .c-hero__title-image {
		max-width: 710px;
	}
}

@media ( min-width: 900px ) {
	.c-hero--charity .c-hero__title-image {
		max-width: 731px;
	}
}

@media ( min-width: 900px ) {
	.c-hero--careers .c-hero__title-image {
		max-width: 690px;
	}
}

/* Hero: Category
   ========================================================================== */
.c-hero__category {
	margin-bottom: 13px;
	font-size: 0.875rem;
	color: #F5BF23;
	letter-spacing: 1px;
}

/* Hero: Content
   ========================================================================== */

.c-hero__content {
	font-size: 0.875rem;
	color: hsl(0, 0%, 100%);
	line-height: 1.2142;
}

@media ( min-width: 600px ) {
	.c-hero__content {
		font-size: 1rem;
		margin-left: 78px;
		padding-left: 26px;
	}
}

@media ( min-width: 900px ) {
	.c-hero__content {
		font-size: 1.25rem;
		width: 55%;
	}
}

.c-hero__content .c-tag {
	font-size: 26px;
	color: var(--color-canary);
}

.c-hero__content .c-highlight {
	margin-top: 0;
	font-size: 32px;
	font-weight: bold;
}

.c-hero__content.inline p:not(:first-of-type) {
	margin-top: 0;
}

/* Hero: Footer
   ========================================================================== */

@media ( min-width: 600px ) {
	.c-hero__footer {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
}

.c-hero__footer .link {
	display: block;
}

@media ( min-width: 600px ) {
	.c-hero__footer .link {
		margin-left: 26px;
		font-size: 0.875rem;
	}

	.c-hero__footer .link .c-icon {
		vertical-align: top;
	}

}

.c-hero__footer .link:not( :first-of-type ) {
	margin-top: 16px;
}

@media ( min-width: 600px ) {
	.c-hero__footer .link:not( :first-of-type ) {
		margin-top: 0;
		margin-left: 13px;
	}
}


/* Hero: Backgrounds
   ========================================================================== */
.c-hero-- {
		background-image:
		linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
		linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
		url( '../images/hero/hero-home-new.jpg' );
	background-blend-mode: multiply, soft-light, normal;
}

.c-hero--contact {
		background-image:
		linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
		linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
		url( '../images/hero/hero-contact.jpg' );
	background-blend-mode: multiply, soft-light, normal;
	background-position: 0 -112px;
}

@media (max-width: 600px) {
.c-hero--contact{
		background-image:
			linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
			linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
			url( '../images/hero/hero-contact_900.jpg' );
		background-blend-mode: multiply, soft-light, normal;
	}
}


.c-hero--restaurant-leisure {
		background-image:
		linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
		linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
		url( '../images/hero/hero-restaurant-leisure.jpg' );
	background-blend-mode: multiply, soft-light, normal;
}

// Business Property Insurance

.c-hero--business-property-insurance {
	background-image:
		linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
		linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
		url( '../images/hero/hero-business-property-insurance_600x400.jpg' );
	background-blend-mode: multiply, soft-light, normal;
}

@media (min-width: 600px) {
	.c-hero--business-property-insurance {
		background-image:
			linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
			linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
			url( '../images/hero/hero-business-property-insurance_900x600.jpg' );
		background-blend-mode: multiply, soft-light, normal;
	}
}

@media (min-width: 900px) {
	.c-hero--business-property-insurance {
		background-image:
			linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
			linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
			url( '../images/hero/hero-business-property-insurance.jpg' );
		background-blend-mode: multiply, soft-light, normal;
	}
}

// Private Clients.

.c-hero--private-client {
	background-image:
		linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
		linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
		url( '../images/hero/hero-private-clients_600x400.jpg' );
	background-blend-mode: multiply, soft-light, normal;
}

@media (min-width: 600px) {
	.c-hero--private-client {
		background-image:
			linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
			linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
			url( '../images/hero/hero-private-clients_900x600.jpg' );
		background-blend-mode: multiply, soft-light, normal;
	}
}

@media (min-width: 900px) {
	.c-hero--private-client {
		background-image:
			linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
			linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
			url( '../images/hero/hero-private-clients_1200x725.jpg' );
		background-blend-mode: multiply, soft-light, normal;
	}
}

// Specialist Services.

.c-hero--specialist-services {
	background-image:
		linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
		linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
		url( '../images/hero/hero-specialist-services_600x400.jpg' );
	background-blend-mode: multiply, soft-light, normal;
}

@media (min-width: 600px) {
	.c-hero--specialist-services {
		background-image:
			linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
			linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
			url( '../images/hero/hero-specialist-services_900x600.jpg' );
		background-blend-mode: multiply, soft-light, normal;
	}
}

@media (min-width: 900px) {
	.c-hero--specialist-services {
		background-image:
			linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
			linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
			url( '../images/hero/hero-specialist-services_1200x725.jpg' );
		background-blend-mode: multiply, soft-light, normal;
	}
}

// About
.c-hero--about {
	background-image:
		linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
		linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
		url( '../images/hero/hero-about2_600x400.jpg' );
	background-blend-mode: multiply, soft-light, normal;
}

@media (min-width: 600px) {
	.c-hero--about {
		background-image:
			linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
			linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
			url( '../images/hero/hero-about2_900x600.jpg' );
		background-blend-mode: multiply, soft-light, normal;
	}
}

@media (min-width: 900px) {
	.c-hero--about {
		background-image:
			linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
			linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
			url( '../images/hero/hero-about2_1200x800.jpg' );
		background-blend-mode: multiply, soft-light, normal;
	}
}

// Claims
.c-hero--claims {
	background-image:
		linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
		linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
		url( '../images/hero/hero-claims_600x400.jpg' );
	background-blend-mode: multiply, soft-light, normal;
}

@media ( min-width: 600px ) {
	.c-hero--claims {
		background-image:
			linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
			linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
			url( '../images/hero/hero-claims_900x600.jpg' );
		background-blend-mode: multiply, soft-light, normal;
	}
}

@media ( min-width: 900px ) {
	.c-hero--claims {
		background-image:
			linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
			linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
			url( '../images/hero/hero-claims_1200x800.jpg' );
		background-blend-mode: multiply, soft-light, normal;
	}
}

// Charity
.c-hero--charity {
	background-image:
		linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
		linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
		url( '../images/hero/hero-charity_600x400.jpg' );
	background-blend-mode: multiply, soft-light, normal;
}

@media ( min-width: 600px ) {
	.c-hero--charity {
		background-image:
			linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
			linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
			url( '../images/hero/hero-charity_900x600.jpg' );
		background-blend-mode: multiply, soft-light, normal;
	}
}

@media ( min-width: 900px ) {
	.c-hero--charity {
		background-image:
			linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
			linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
			url( '../images/hero/hero-charity_1200x800.jpg' );
		background-blend-mode: multiply, soft-light, normal;
	}
}

// Jobs
.c-hero--careers {
	background-image:
		linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
		linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
		url( '../images/hero/hero-job_600x400.jpg' );
	background-blend-mode: multiply, soft-light, normal;
}

@media ( min-width: 600px ) {
	.c-hero--careers {
		background-image:
			linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
			linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
			url( '../images/hero/hero-job_900x600.jpg' );
		background-blend-mode: multiply, soft-light, normal;
	}
}

@media ( min-width: 900px ) {
	.c-hero--careers {
		background-image:
			linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
			linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
			url( '../images/hero/hero-job_1200x800.jpg' );
		background-blend-mode: multiply, soft-light, normal;
	}
}

// Continuity Planning
.c-hero--business-continuity-planning {
	background-image:
		linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
		linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
		url( '../images/hero/hero-business-continuity_600x363.jpg' );
	background-blend-mode: multiply, soft-light, normal;
}

@media ( min-width: 600px ) {
	.c-hero--business-continuity-planning {
		background-image:
			linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
			linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
			url( '../images/hero/hero-business-continuity_900x544.jpg' );
		background-blend-mode: multiply, soft-light, normal;
	}
}

@media ( min-width: 900px ) {
	.c-hero--business-continuity-planning {
		background-image:
			linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
			linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
			url( '../images/hero/hero-business-continuity_1200x725.jpg' );
		background-blend-mode: multiply, soft-light, normal;
	}
}

// Risk Management
.c-hero--risk-management-services {
	background-image:
		linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
		linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
		url( '../images/hero/hero-risk-management_600x363.jpg' );
	background-blend-mode: multiply, soft-light, normal;
}

@media ( min-width: 600px ) {
	.c-hero--risk-management-services {
		background-image:
			linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
			linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
			url( '../images/hero/hero-risk-management_900x544.jpg' );
		background-blend-mode: multiply, soft-light, normal;
	}
}

@media ( min-width: 900px ) {
	.c-hero--risk-management-services {
		background-image:
			linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
			linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
			url( '../images/hero/hero-risk-management_1200x725.jpg' );
		background-blend-mode: multiply, soft-light, normal;
	}
}

// Health & Safety
.c-hero--health-safety {
	background-image:
		linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
		linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
		url( '../images/hero/hero-health-saftey_600x363.jpg' );
	background-blend-mode: multiply, soft-light, normal;
}

@media ( min-width: 600px ) {
	.c-hero--health-safety {
		background-image:
			linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
			linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
			url( '../images/hero/hero-health-saftey_900x544.jpg' );
		background-blend-mode: multiply, soft-light, normal;
	}
}

@media ( min-width: 900px ) {
	.c-hero--health-safety {
		background-image:
			linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
			linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
			url( '../images/hero/hero-health-saftey_1200x725.jpg' );
		background-blend-mode: multiply, soft-light, normal;
	}
}

// High Value Household
.c-hero--high-net-worth-household-insurance {
	background-image:
		linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
		linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
		url( '../images/hero/hero-high-value_600x363.jpg' );
	background-blend-mode: multiply, soft-light, normal;
}

@media ( min-width: 600px ) {
	.c-hero--high-net-worth-household-insurance {
		background-image:
			linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
			linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
			url( '../images/hero/hero-high-value_900x544.jpg' );
		background-blend-mode: multiply, soft-light, normal;
	}
}

@media ( min-width: 900px ) {
	.c-hero--high-net-worth-household-insurance {
		background-image:
			linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
			linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
			url( '../images/hero/hero-high-value_1200x725.jpg' );
		background-blend-mode: multiply, soft-light, normal;
	}
}

// Fine Art
.c-hero--fine-art-insurance {
	background-image:
		linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
		linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
		url( '../images/hero/hero-fine-art_600x363.jpg' );
	background-blend-mode: multiply, soft-light, normal;
}

@media ( min-width: 600px ) {
	.c-hero--fine-art-insurance {
		background-image:
			linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
			linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
			url( '../images/hero/hero-fine-art_900x544.jpg' );
		background-blend-mode: multiply, soft-light, normal;
	}
}

@media ( min-width: 900px ) {
	.c-hero--fine-art-insurance {
		background-image:
			linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
			linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
			url( '../images/hero/hero-fine-art_1200x725.jpg' );
		background-blend-mode: multiply, soft-light, normal;
	}
}

// Motor
.c-hero--high-value-motor-insurance {
	background-image:
		linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
		linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
		url( '../images/hero/hero-motors_600x363.jpg' );
	background-blend-mode: multiply, soft-light, normal;
}

@media ( min-width: 600px ) {
	.c-hero--high-value-motor-insurance {
		background-image:
			linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
			linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
			url( '../images/hero/hero-motors_900x544.jpg' );
		background-blend-mode: multiply, soft-light, normal;
	}
}

@media ( min-width: 900px ) {
	.c-hero--high-value-motor-insurance {
		background-image:
			linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
			linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
			url( '../images/hero/hero-motors_1200x725.jpg' );
		background-blend-mode: multiply, soft-light, normal;
	}
}

// Household
.c-hero--household-insurance {
	background-image:
		linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
		linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
		url( '../images/hero/hero-household_600x363.jpg' );
	background-blend-mode: multiply, soft-light, normal;
}

@media ( min-width: 600px ) {
	.c-hero--household-insurance {
		background-image:
			linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
			linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
			url( '../images/hero/hero-household_900x544.jpg' );
		background-blend-mode: multiply, soft-light, normal;
	}
}

@media ( min-width: 900px ) {
	.c-hero--household-insurance {
		background-image:
			linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
			linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
			url( '../images/hero/hero-household_1200x725.jpg' );
		background-blend-mode: multiply, soft-light, normal;
	}
}

// Commercial Indistrial
.c-hero--commercial-industrial {
	background-image:
		linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
		linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
		url( '../images/hero/hero-commerical-insurance_600x400.jpg' );
	background-blend-mode: multiply, soft-light, normal;
}

@media ( min-width: 600px ) {
	.c-hero--commercial-industrial {
		background-image:
			linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
			linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
			url( '../images/hero/hero-commerical-insurance_900x600.jpg' );
		background-blend-mode: multiply, soft-light, normal;
	}
}

@media ( min-width: 900px ) {
	.c-hero--commercial-industrial {
		background-image:
			linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
			linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
			url( '../images/hero/hero-commerical-insurance_1200x725.jpg' );
		background-blend-mode: multiply, soft-light, normal;
	}
}

// Redidential Blocks of Flats
.c-hero--residential-blocks-of-flats {
	background-image:
		linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
		linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
		url( '../images/hero/hero-residential-insurance_600x400.jpg' );
	background-blend-mode: multiply, soft-light, normal;
}

@media ( min-width: 600px ) {
	.c-hero--residential-blocks-of-flats {
		background-image:
			linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
			linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
			url( '../images/hero/hero-residential-insurance_900x600.jpg' );
		background-blend-mode: multiply, soft-light, normal;
	}
}

@media ( min-width: 900px ) {
	.c-hero--residential-blocks-of-flats {
		background-image:
			linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
			linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
			url( '../images/hero/hero-residential-insurance_1200x725.jpg' );
		background-blend-mode: multiply, soft-light, normal;
	}
}

// Professional
.c-hero--professional {
	background-image:
		linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
		linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
		url( '../images/hero/hero-professional_600x363.jpg' );
	background-blend-mode: multiply, soft-light, normal;
}

@media ( min-width: 600px ) {
	.c-hero--professional {
		background-image:
			linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
			linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
			url( '../images/hero/hero-professional_900x544.jpg' );
		background-blend-mode: multiply, soft-light, normal;
	}
}

@media ( min-width: 900px ) {
	.c-hero--professional {
		background-image:
			linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
			linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
			url( '../images/hero/hero-professional_1200x725.jpg' );
		background-blend-mode: multiply, soft-light, normal;
	}
}

// Media & IT
.c-hero--media-it {
	background-image:
		linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
		linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
		url( '../images/hero/hero-media_600x363.jpg' );
	background-blend-mode: multiply, soft-light, normal;
}

@media ( min-width: 600px ) {
	.c-hero--media-it {
		background-image:
			linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
			linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
			url( '../images/hero/hero-media_900x544.jpg' );
		background-blend-mode: multiply, soft-light, normal;
	}
}

@media ( min-width: 900px ) {
	.c-hero--media-it {
		background-image:
			linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
			linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
			url( '../images/hero/hero-media_1200x725.jpg' );
		background-blend-mode: multiply, soft-light, normal;
	}
}

// Import & Export
.c-hero--import-export {
	background-image:
		linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
		linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
		url( '../images/hero/hero-export_600x363.jpg' );
	background-blend-mode: multiply, soft-light, normal;
}

@media ( min-width: 600px ) {
	.c-hero--import-export {
		background-image:
			linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
			linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
			url( '../images/hero/hero-export_900x544.jpg' );
		background-blend-mode: multiply, soft-light, normal;
	}
}

@media ( min-width: 900px ) {
	.c-hero--import-export {
		background-image:
			linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
			linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
			url( '../images/hero/hero-export_1200x725.jpg' );
		background-blend-mode: multiply, soft-light, normal;
	}
}

// Construction
.c-hero--construction {
	background-image:
		linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
		linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
		url( '../images/hero/hero-construction2_600x363.jpg' );
	background-blend-mode: multiply, soft-light, normal;
}

@media ( min-width: 600px ) {
	.c-hero--construction {
		background-image:
			linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
			linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
			url( '../images/hero/hero-construction2_900x544.jpg' );
		background-blend-mode: multiply, soft-light, normal;
	}
}

@media ( min-width: 900px ) {
	.c-hero--construction {
		background-image:
			linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
			linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
			url( '../images/hero/hero-construction2_1200x725.jpg' );
		background-blend-mode: multiply, soft-light, normal;
	}
}

// Medical Devices
.c-hero--medical-devices {
	background-image:
		linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
		linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
		url( '../images/hero/hero-medical_600x363.jpg' );
	background-blend-mode: multiply, soft-light, normal;
}

@media ( min-width: 600px ) {
	.c-hero--medical-devices {
		background-image:
			linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
			linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
			url( '../images/hero/hero-medical_900x544.jpg' );
		background-blend-mode: multiply, soft-light, normal;
	}
}

@media ( min-width: 900px ) {
	.c-hero--medical-devices {
		background-image:
			linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
			linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
			url( '../images/hero/hero-medical_1200x725.jpg' );
		background-blend-mode: multiply, soft-light, normal;
	}
}

// Commercial Industrial
.c-hero--commercial-industrial {
	background-image:
		linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
		linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
		url( '../images/hero/hero-comm-indus_600x363.jpg' );
	background-blend-mode: multiply, soft-light, normal;
}

@media ( min-width: 600px ) {
	.c-hero--commercial-industrial {
		background-image:
			linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
			linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
			url( '../images/hero/hero-comm-indus_900x544.jpg' );
		background-blend-mode: multiply, soft-light, normal;
	}
}

@media ( min-width: 900px ) {
	.c-hero--commercial-industrial {
		background-image:
			linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
			linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
			url( '../images/hero/hero-comm-indus_1200x725.jpg' );
		background-blend-mode: multiply, soft-light, normal;
	}
}

// Small
.c-hero--small {
	background-image:
		linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
		linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
		url( '../images/hero/hero-small_600x338.jpg' );
	background-blend-mode: multiply, soft-light, normal;
	background-position: center bottom;
}

@media ( min-width: 600px ) {
	.c-hero--small {
		background-image:
			linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
			linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
			url( '../images/hero/hero-small_900x506.jpg' );
		background-blend-mode: multiply, soft-light, normal;
	}
}

@media ( min-width: 900px ) {
	.c-hero--small {
		background-image:
			linear-gradient(180deg, rgba(254, 203, 38,0) 0%,rgba(83, 86, 90,0.85) 100%),
			linear-gradient(45deg, rgba(254, 203, 38,0.5) 0%,rgba(83, 86, 90,0.5) 100%),
			url( '../images/hero/hero-small_1200x675.jpg' );
		background-blend-mode: multiply, soft-light, normal;
	}
}

/*
  == */

.c-hero--small {
	height: 416px;
}

.c-hero--overlap.c-hero--small {
	margin-bottom: -234px;
}

.brown-brown-logo{
	display: flex;
	width:100%;
	justify-content: flex-end;

	.c-brown-brown-image{
		max-width: 280px;
	}
}
