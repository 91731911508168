.c-container {
	margin-left: auto;
	margin-right: auto;
	width: 90%;
	max-width: 1200px;
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: cover;
}

.c-container--narrow {
	width: 50%;
	max-width: 1000px;
}

.c-container__narrow {
	margin-right: auto;
	margin-left: auto;
}

@media ( min-width: 600px ) {
	.c-container__narrow {
		width: 70%;
		max-width: 500px;
	}
}

@media ( min-width: 900px ) {
	.c-container__narrow {
		width: 80%;
		max-width: 666px;
	}
}

.c-container--small {
	width: 90%;
	max-width: 540px;
}

@media ( min-width: 600px ) {
	.c-container--small {
		width: 80%;
		max-width: 720px;
	}
}

@media ( min-width: 900px ) {
	.c-container--small {
		width: 75%;
		max-width: 925px;
	}
}

.c-border-top {
	border-top: $border-width-thin solid #e3e3e3;
	padding-top: 26px;
	padding-bottom: 26px;
}

@media (min-width: 900px) {
	.c-border-top {
		padding-top: 39px;
		padding-bottom: 39px;
	}
}

@media (min-width: 1200px) {
	.c-border-top {
		padding-top: 52px;
		padding-bottom: 52px;
	}
}

.c-border-bottom {
	border-bottom: 1pt solid #e3e3e3;
}

.c-shadow {
	box-shadow: 0 0 7px 4px hsla(0, 0%, 0%, 0.04);
}

.page-enquire .c-title,
.page-enquire .form__description {
	text-align: center;
}

.c-claims__list {
	position: relative;
}

@media (min-width: 1200px ) {
	.c-claims__list::before,
	.c-claims__list::after {
		content: '';
		position: absolute;
		display: block;
		width: 82px;
		height: 356px;
		background-image: url( '/dist/images/side-pattern.png' );
		background-repeat: no-repeat;
	}

	.c-claims__list::before {
		top: 25%;
		left: -41px;
	}

	.c-claims__list::after {
		right: -41px;
		bottom: 25%;
	}

}

.terms-listing {
  margin: 0 0 0 20px;
}
