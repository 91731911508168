/**
 * Headings.
 */

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
	margin-bottom: 0;
	clear: both;
	//font-family: $font-family-primary;
}

h1 {
	//font-size: $font-size-xx-large;
	//line-height: $line-height-heading;
}

h2,
h3,
h4,
h5,
h6 {
	//font-size: $font-size-x-large;
	//line-height: $line-height-heading;
}
