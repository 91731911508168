.c-services--list {
	padding-top: 13px;
	padding-bottom: 13px;
}

.c-services--list .c-service {
	margin-left: auto;
	margin-right: auto;
	display: grid;
	grid-template-columns: repeat( 1, 1fr);
	grid-gap: 26px;
}

@media ( min-width: 600px ) {
	.c-services--list .c-service {
		grid-template-columns: repeat( 2, 1fr);
	}
}

@media ( min-width: 900px ) {
	.c-services--list .c-service {
		grid-template-columns: repeat( 3, 1fr);
  }
}

/* IE 11 Fix */
@media ( min-width: 600px ) and ( max-width: 899px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {

	.c-services--list .c-card--service {
		margin-right: 13px;
		margin-left: 13px;
	}

	.c-services--list .c-card--service:nth-child(1) {
		grid-column: 1;
		grid-row: 1;
	}
	.c-services--list .c-card--service:nth-child(2) {
		grid-column: 2;
		grid-row: 1;
	}
	.c-services--list .c-card--service:nth-child(3) {
		grid-column: 1;
		grid-row: 2;
	}
}

@media ( min-width: 900px ) and (-ms-high-contrast: none), (-ms-high-contrast: active) {

	.c-services--list .c-card--service {
		margin-right: 19.5px;
		margin-left: 19.5px;
	}

	.c-services--list .c-card--service:nth-child(1) {
		grid-column: 1;
		grid-row: 1;
	}
	.c-services--list .c-card--service:nth-child(2) {
		grid-column: 2;
		grid-row: 1;
	}
	.c-services--list .c-card--service:nth-child(3) {
		grid-column: 3;
		grid-row: 1;
	}
}
