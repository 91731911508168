/**
 * Gallery
 * Example:
      <ul class="gallery"">
        <li class="gallery__item">
          <img src="example.jpg" alt="">
        </li>
      </ul>
 *
 */

.o-gallery {
	width: 100%;
	text-align: center;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
}

.o-gallery__item {
	display: inline-block;
}

.o-gallery__item img {
  max-width: 100%;
	height: auto;
}
