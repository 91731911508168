.c-footer {
	padding-top: 28px;
	padding-bottom: 28px;
	background-color: var(--color-petrol);
	color: hsl(0, 0%, 100%);
}

.c-footer .c-container {

}

@media ( min-width: 900px ) {
	.c-footer .c-container {
		display: grid;
		grid-column-gap: 0;
		grid-template-columns: 50fr 50fr;
	}
}

.c-footer__nav {
	font-size: $font-size-small;
	color: var(--color-canary);
	text-align: center;
	line-height: 1.1666;
}

@media ( min-width: 900px ) {
	.c-footer__nav {
		text-align: right;
	}
}

.c-footer__nav ul {
	margin: 0;
	padding: 0;
	padding-left: 0;
	list-style: none;
}

.c-footer__nav a {
	display: inline-block;
	color: var(--color-canary);
	margin-bottom: 5px;
}

.c-footer__nav a:hover,
.c-footer__nav a:focus {
	text-decoration: underline;
}

.website-by{
	display: block;
	line-height: 1;
}
.website-by a{
	font-size: $font-size-small;
	color: var(--color-canary);
	text-align: center;
	line-height: 1.1666;
}

/* Meta
   ========================================================================== */
.c-footer__meta {
	text-align: center;
}

@media ( min-width: 900px ) {
	.c-footer__meta {
		grid-column: 2;
		grid-row: 2;
		text-align: right;
	}
}

.c-footer__address {
	font-size: $font-size-small;
	color: hsl(0, 0%, 100%);
	margin-bottom:5px;

}

.c-footer__details {
	font-size: $font-size-small;
	color: white;
	text-align: center;
	line-height: 1.5;
}

@media ( min-width: 900px ) {
	.c-footer__details {
		grid-column: 1;
		grid-row: 2;
		text-align: left;
	}
}

/* Footer: Social
   ========================================================================== */
.c-footer__social {
	margin-bottom: 7px;
	text-align: center;
	color: var(--color-canary);
}

@media ( min-width: 900px ) {
	.c-footer__social {
		grid-column: 2;
		grid-row: 1;
		align-self: end;
		margin-bottom: 18px;
		text-align: right;
	}
}

.c-footer__social a {
	color: var(--color-canary);
	margin-right: 0.75rem;
	margin-left: 0.75rem;
}

/* Footer: Logo
   ========================================================================== */

.c-footer .logo {
	margin-bottom: 30px;
	text-align: center;
}

@media ( min-width: 900px ) {
	.c-footer .logo {
		grid-column: 1;
		grid-row: 1;
		margin-bottom: 18px;
		text-align: left;
	}
}

.c-footer .logo img {
	width: 133px;
}
