.c-insurance {
	position: relative;
	padding-bottom: 26px;
}

.c-insurance .c-container {
	display: grid;
	grid-column-gap: 26px;
	grid-row-gap: 26px;
	padding-bottom: 13px;
}

@media ( min-width: 600px ) {
	.c-insurance .c-container {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
}

/* Hack layout for IE11 Support */
@media all and (-ms-high-contrast:none) {
	.c-insurance .c-container {
		display: block;
	}

	.c-insurance .c-container .c-card {
		margin-right: 13px;
		margin-bottom: 26px;
		margin-left: 13px;
	}
}

@media ( min-width: 900px ) {
	.c-insurance .c-container {
		display: grid;
		grid-template-columns: repeat( 6, 1fr );
		grid-column: auto / span 2;
	}

	.c-insurance .c-container .c-card:nth-child(1) {
		grid-column-start: 1;
		grid-column-end: span 3;
	}

	.c-insurance .c-container .c-card:nth-child(2) {
		grid-column-start: 4;
		grid-column-end: span 3;
	}

	.c-insurance .c-container .c-card:nth-child(3) {
		grid-column-start: 1;
		grid-column-end: span 2;
		grid-row: 2;
	}

	.c-insurance .c-container .c-card:nth-child(4) {
		grid-column-start: 3;
		grid-column-end: span 2;
		grid-row: 2;
	}

	.c-insurance .c-container .c-card:nth-child(5) {
		grid-column-start: 5;
		grid-column-end: span 2;
		grid-row: 2;
	}

	.c-insurance .c-container .c-card:nth-child(6) {
		grid-column-start: 1;
		grid-column-end: span 2;
		grid-row: 3;
	}

	.c-insurance .c-container .c-card:nth-child(7) {
		grid-column-start: 3;
		grid-column-end: span 2;
		grid-row: 3;
	}

	.c-insurance .c-container .c-card:nth-child( 8 ) {
		grid-column-start: 5;
		grid-column-end: span 2;
		grid-row: 3;
	}
	
	.c-insurance .c-container .c-card:nth-child( 9 ) {
		grid-column-start: 1;
		grid-column-end: span 3;
		grid-row: 4;
	}

	.c-insurance .c-container .c-card:nth-child( 10 ) {
		grid-column-start: 4;
		grid-column-end: span 3;
		grid-row: 4;
	}

	.c-insurance .c-container .c-card {
		.c-card__header .c-title--primary {
			color: var(--color-canary);
		}
	}

}

/* adviser Option
   ========================================================================== */
.c-insurance--adviser {
	position: relative;
	background-color: #fff;
	background-clip: padding-box;
	border: 13px solid transparent;
}

.c-insurance--adviser::before {
	content: '';
	position: absolute;
	top: 0; right: 0; bottom: 0; left: 0;
	z-index: -10;
	margin: -13px;
	border-radius: inherit;
	background-image: linear-gradient(
		to bottom,
		var(--color-petrol) 0%,
		var(--color-canary) 100%
	);
}

.c-insurance--adviser .c-card__content::before,
.c-insurance--adviser .c-card__content::after {
	content: '';
	position: absolute;
	width: 13px;
	height: 78px;
	background-color: #fff;
}

.c-insurance--adviser .c-card__content::before {
	top: 65px;
	left: -13px;
}

.c-insurance--adviser .c-card__content::after {
	bottom: 65px;
	right: -13px;
}
