.c-team__list .c-container {
	padding-top: 13px;
	padding-bottom: 26px;
}

.c-team__list .c-container {
	margin-left: auto;
	margin-right: auto;
	display: grid;
	grid-template-columns: repeat( 1, 1fr);
	grid-gap: 26px;
}

@media ( min-width: 600px ) {
	.c-team__list .c-container {
		grid-template-columns: repeat( 2, 1fr);
	}
}

@media ( min-width: 900px ) {
	.c-team__list .c-container {
		grid-template-columns: repeat( 3, 1fr);
		padding-bottom: 39px;
  }
}

@media ( min-width: 1200px ) {
	.c-team__list .c-container {
		grid-template-columns: repeat( 4, 1fr);
		margin-bottom: 52px;
  }
}

.c-team .c-icon--email {
	margin-left: 0;
}

@media ( min-width: 600px ) {
	.c-team .c-title--secondary {
		min-height: 39px;
	}
}

.c-team .c-title--primary + .c-title--secondary {
	margin-top: 0;
}

.c-card--person .c-title--primary {
	font-size: 1rem;
}

.c-card--person .c-title--secondary {
	color: #767676;
	font-size: 1rem;
	font-weight: normal;
	line-height: 1.125;
}

/*
 * 1. Allows the absolute positioning of the linkedin icon.
   ==== */
.c-team .c-card__header {
	position: relative; /* [1] */
	margin-bottom: 6.5px;
	padding-right: 39px; /* [1] */
}

.c-team .c-icon--brand-linkedin {
	position: absolute;
	top: 0;
	right: 0;
}

/* IE 11 Fixes
   ====== */
@media ( min-width: 600px ) and ( max-width: 899px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {

	.c-team__list .c-card--person {
		margin-right: 13px;
		margin-left: 13px;
	}

	.c-team__list .c-card--person:nth-child(1) {
		grid-column: 1;
		grid-row: 1;
	}
	.c-team__list .c-card--person:nth-child(2) {
		grid-column: 2;
		grid-row: 1;
	}
	.c-team__list .c-card--person:nth-child(3) {
		grid-column: 1;
		grid-row: 2;
	}
	.c-team__list .c-card--person:nth-child(4) {
		grid-column: 2;
		grid-row: 2;
	}
	.c-team__list .c-card--person:nth-child(5) {
		grid-column: 1;
		grid-row: 3;
	}
	.c-team__list .c-card--person:nth-child(6) {
		grid-column: 2;
		grid-row: 3;
	}
	.c-team__list .c-card--person:nth-child(7) {
		grid-column: 1;
		grid-row: 4;
	}
	.c-team__list .c-card--person:nth-child(8) {
		grid-column: 2;
		grid-row: 4;
	}
	.c-team__list .c-card--person:nth-child(9) {
		grid-column: 1;
		grid-row: 5;
	}
	.c-team__list .c-card--person:nth-child(10) {
		grid-column: 2;
		grid-row: 5;
	}
	.c-team__list .c-card--person:nth-child(11) {
		grid-column: 1;
		grid-row: 6;
	}
	.c-team__list .c-card--person:nth-child(12) {
		grid-column: 2;
		grid-row: 6;
	}
	.c-team__list .c-card--person:nth-child(13) {
		grid-column: 1;
		grid-row: 7;
	}
	.c-team__list .c-card--person:nth-child(14) {
		grid-column: 2;
		grid-row: 7;
	}
	.c-team__list .c-card--person:nth-child(15) {
		grid-column: 1;
		grid-row: 8;
	}
	.c-team__list .c-card--person:nth-child(16) {
		grid-column: 2;
		grid-row: 8;
	}
	.c-team__list .c-card--person:nth-child(17) {
		grid-column: 1;
		grid-row: 9;
	}
	.c-team__list .c-card--person:nth-child(18) {
		grid-column: 2;
		grid-row: 9;
	}
	.c-team__list .c-card--person:nth-child(19) {
		grid-column: 1;
		grid-row: 10;
	}
	.c-team__list .c-card--person:nth-child(20) {
		grid-column: 2;
		grid-row: 10;
	}
	.c-team__list .c-card--person:nth-child(21) {
		grid-column: 1;
		grid-row: 11;
	}
	.c-team__list .c-card--person:nth-child(22) {
		grid-column: 2;
		grid-row: 11;
	}
	.c-team__list .c-card--person:nth-child(23) {
		grid-column: 1;
		grid-row: 12;
	}
	.c-team__list .c-card--person:nth-child(24) {
		grid-column: 1;
		grid-row: 12;
	}
	.c-team__list .c-card--person:nth-child(25) {
		grid-column: 1;
		grid-row: 13;
	}
	.c-team__list .c-card--person:nth-child(26) {
		grid-column: 2;
		grid-row: 13;
	}
	.c-team__list .c-card--person:nth-child(27) {
		grid-column: 1;
		grid-row: 14;
	}
	.c-team__list .c-card--person:nth-child(28) {
		grid-column: 2;
		grid-row: 14;
	}
	.c-team__list .c-card--person:nth-child(29) {
		grid-column: 1;
		grid-row: 15;
	}
	.c-team__list .c-card--person:nth-child(30) {
		grid-column: 2;
		grid-row: 15;
	}
}

@media ( min-width: 900px ) and ( max-width: 1199px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {

	.c-team__list .c-card--person {
		margin-right: 19.5px;
		margin-left: 19.5px;
	}

	.c-team__list .c-card--person:nth-child(1) {
		grid-column: 1;
		grid-row: 1;
	}
	.c-team__list .c-card--person:nth-child(2) {
		grid-column: 2;
		grid-row: 1;
	}
	.c-team__list .c-card--person:nth-child(3) {
		grid-column: 3;
		grid-row: 1;
	}
	.c-team__list .c-card--person:nth-child(4) {
		grid-column: 1;
		grid-row: 2;
	}
	.c-team__list .c-card--person:nth-child(5) {
		grid-column: 2;
		grid-row: 2;
	}
	.c-team__list .c-card--person:nth-child(6) {
		grid-column: 3;
		grid-row: 2;
	}
	.c-team__list .c-card--person:nth-child(7) {
		grid-column: 1;
		grid-row: 3;
	}
	.c-team__list .c-card--person:nth-child(8) {
		grid-column: 2;
		grid-row: 3;
	}
	.c-team__list .c-card--person:nth-child(9) {
		grid-column: 3;
		grid-row: 3;
	}
	.c-team__list .c-card--person:nth-child(10) {
		grid-column: 1;
		grid-row: 4;
	}
	.c-team__list .c-card--person:nth-child(11) {
		grid-column: 2;
		grid-row: 4;
	}
	.c-team__list .c-card--person:nth-child(12) {
		grid-column: 3;
		grid-row: 4;
	}
	.c-team__list .c-card--person:nth-child(13) {
		grid-column: 1;
		grid-row: 5;
	}
	.c-team__list .c-card--person:nth-child(14) {
		grid-column: 2;
		grid-row: 5;
	}
	.c-team__list .c-card--person:nth-child(15) {
		grid-column: 3;
		grid-row: 5;
	}
	.c-team__list .c-card--person:nth-child(16) {
		grid-column: 1;
		grid-row: 6;
	}
	.c-team__list .c-card--person:nth-child(17) {
		grid-column: 2;
		grid-row: 6;
	}
	.c-team__list .c-card--person:nth-child(18) {
		grid-column: 3;
		grid-row: 6;
	}
	.c-team__list .c-card--person:nth-child(19) {
		grid-column: 1;
		grid-row: 7;
	}
	.c-team__list .c-card--person:nth-child(20) {
		grid-column: 2;
		grid-row: 7;
	}
	.c-team__list .c-card--person:nth-child(21) {
		grid-column: 3;
		grid-row: 7;
	}
	.c-team__list .c-card--person:nth-child(22) {
		grid-column: 1;
		grid-row: 8;
	}
	.c-team__list .c-card--person:nth-child(23) {
		grid-column: 2;
		grid-row: 8;
	}
	.c-team__list .c-card--person:nth-child(24) {
		grid-column: 3;
		grid-row: 8;
	}
	.c-team__list .c-card--person:nth-child(25) {
		grid-column: 1;
		grid-row: 9;
	}
	.c-team__list .c-card--person:nth-child(26) {
		grid-column: 2;
		grid-row: 9;
	}
	.c-team__list .c-card--person:nth-child(27) {
		grid-column: 3;
		grid-row: 9;
	}
	.c-team__list .c-card--person:nth-child(28) {
		grid-column: 1;
		grid-row: 10;
	}
	.c-team__list .c-card--person:nth-child(29) {
		grid-column: 2;
		grid-row: 10;
	}
	.c-team__list .c-card--person:nth-child(30) {
		grid-column: 3;
		grid-row: 10;
	}
}

@media ( min-width: 1200px ) and (-ms-high-contrast: none), (-ms-high-contrast: active) {

	.c-team__list .c-card--person {
		margin-right: 19.5px;
		margin-left: 19.5px;
	}

	.c-team__list .c-card--person:nth-child(1) {
		grid-column: 1;
		grid-row: 1;
	}
	.c-team__list .c-card--person:nth-child(2) {
		grid-column: 2;
		grid-row: 1;
	}
	.c-team__list .c-card--person:nth-child(3) {
		grid-column: 3;
		grid-row: 1;
	}
	.c-team__list .c-card--person:nth-child(4) {
		grid-column: 4;
		grid-row: 1;
	}
	.c-team__list .c-card--person:nth-child(5) {
		grid-column: 1;
		grid-row: 2;
	}
	.c-team__list .c-card--person:nth-child(6) {
		grid-column: 2;
		grid-row: 2;
	}
	.c-team__list .c-card--person:nth-child(7) {
		grid-column: 3;
		grid-row: 2;
	}
	.c-team__list .c-card--person:nth-child(8) {
		grid-column: 4;
		grid-row: 2;
	}
	.c-team__list .c-card--person:nth-child(9) {
		grid-column: 1;
		grid-row: 3;
	}
	.c-team__list .c-card--person:nth-child(10) {
		grid-column: 2;
		grid-row: 3;
	}
	.c-team__list .c-card--person:nth-child(11) {
		grid-column: 3;
		grid-row: 3;
	}
	.c-team__list .c-card--person:nth-child(12) {
		grid-column: 4;
		grid-row: 3;
	}
	.c-team__list .c-card--person:nth-child(13) {
		grid-column: 1;
		grid-row: 4;
	}
	.c-team__list .c-card--person:nth-child(14) {
		grid-column: 2;
		grid-row: 4;
	}
	.c-team__list .c-card--person:nth-child(15) {
		grid-column: 3;
		grid-row: 4;
	}
	.c-team__list .c-card--person:nth-child(16) {
		grid-column: 4;
		grid-row: 4;
	}
	.c-team__list .c-card--person:nth-child(17) {
		grid-column: 1;
		grid-row: 5;
	}
	.c-team__list .c-card--person:nth-child(18) {
		grid-column: 2;
		grid-row: 5;
	}
	.c-team__list .c-card--person:nth-child(19) {
		grid-column: 3;
		grid-row: 5;
	}
	.c-team__list .c-card--person:nth-child(20) {
		grid-column: 4;
		grid-row: 5;
	}
	.c-team__list .c-card--person:nth-child(21) {
		grid-column: 1;
		grid-row: 6;
	}
	.c-team__list .c-card--person:nth-child(22) {
		grid-column: 2;
		grid-row: 6;
	}
	.c-team__list .c-card--person:nth-child(23) {
		grid-column: 3;
		grid-row: 6;
	}
	.c-team__list .c-card--person:nth-child(24) {
		grid-column: 4;
		grid-row: 6;
	}
	.c-team__list .c-card--person:nth-child(25) {
		grid-column: 1;
		grid-row: 7;
	}
	.c-team__list .c-card--person:nth-child(26) {
		grid-column: 2;
		grid-row: 7;
	}
	.c-team__list .c-card--person:nth-child(27) {
		grid-column: 3;
		grid-row: 7;
	}
	.c-team__list .c-card--person:nth-child(28) {
		grid-column: 4;
		grid-row: 7;
	}
	.c-team__list .c-card--person:nth-child(29) {
		grid-column: 1;
		grid-row: 8;
	}
	.c-team__list .c-card--person:nth-child(30) {
		grid-column: 2;
		grid-row: 8;
	}
}
