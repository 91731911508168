.c-mask {
	background-color: hsl(0, 0%, 0%);
	background-image:
		linear-gradient(45deg, rgba(83, 86, 90, 0.5) 0%,rgba(254, 203, 38, 0.5) 100%);
	mask-image: url( '../svg/mask-brand.svg' );
	mask-position: center;
	mask-repeat: no-repeat;
	mask-size: 100% 100%;
	overflow: hidden; /* [1] IE 11 Fix. */
}

.c-team .c-mask {
	mask-image: url( '../svg/mask-person.svg' );
}

.c-mask img {
	mix-blend-mode: screen;
}

.c-mask .c-icon {
	color: #fff;
}

.c-pattern {
	background-image: url( '../images/background-pattern.png' );
	background-repeat: repeat-x;
}

.c-pattern.c-insurance {
	background-position-y: 104px;
}

.c-pattern.c-insurance::before {
	content: '';
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 147px;
	background-image: url( '../images/background-pattern.png' );
	background-repeat: repeat-x;
	z-index: -20;
}
