/**
 * The list-inline object displays any list of items as a single line.
 */

.o-list {
	margin-left: 0;
	padding-left: 0;
	list-style: none;
}

/* li */.o-list--inline .o-list__item {
	display: inline-block;
}

.o-list--separator .o-list__item:not( :last-child )::after {
	margin-right: 0.5rem;
	margin-left: 0.25rem;
	content: '\007C';
}
