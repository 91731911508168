.link:visited,
.link:active,
.link:link {
	font-weight: $font-weight-normal;
	color: var(--color-petrol);
	text-decoration: none;
	text-transform: uppercase;
}

.link:hover,
.link:focus {
	color: var(--color-canary);
}

.t-dark.link:visited,
.t-dark.link:active,
.t-dark.link:link {
	color: hsl(0, 0%, 100%);
	text-decoration: none;
}

.link--primary:visited,
.link--primary:active,
.link--primary:link {
	color: var(--color-canary);
	text-decoration: none;
}

.link.link--arrow {
	display: flex;
  align-items: flex-start;
}

.c-icon--link-arrow {
	margin-right: 8px;
	width: 26px;
}

/* Telephone Numbers
   ========================================================================== */
.tel {
	font-weight: bold;
}

.tel:visited,
.tel:active,
.tel:link {
	color: initial;
}

.tel:hover,
.tel:focus {
	color: initial;
	text-decoration: underline;
}

.c-highlight-link{
	text-decoration: underline;
    color: var(--color-forest);
    font-weight: bold;
}