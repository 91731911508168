// Main style sheet
// Combines all of the modular parts into one overall file.

// START GLOBAL OVERRIDES

// END GLOBAL OVERRIDES
// Run the @import processes to build the project.
// Separate file to the style sheet that uses this build process.
// This allows multiple stylesheets to be built from one project.
// E.g. you may want to build a critical and non-critical stylesheet by overriding some global variables.

// Import core settings & tools

:root {
	--color-grey: #53565a;
	--color-canary: #fecb26;
	--color-forest: #139996;
	--color-sunrise: #f39200;
	--color-iris: #30c6d7;
	--color-ocean: #00658f;
	--color-petrol: #21314b;

	--color-canary: #F1BC46;
	--color-sunrise: #F1BC46;
}

@import "settings/_setting.animation.scss";
@import "settings/_settings.colors.scss";
@import "settings/_settings.structure.scss";
@import "settings/_settings.typography.scss";



@import "generic/__normalize.scss";
@import "generic/_generic.box-sizing.scss";
@import "generic/_generic.reset.scss";

@import "elements/_elements.headings.scss";
@import "elements/_elements.images.scss";
@import "elements/_elements.links.scss";
@import "elements/_elements.typography.scss";

@import "objects/_object.gallery.scss";
@import "objects/_object.list.scss";

@import "components/_accreditation.scss";
@import "components/_advisor-bar.scss";
@import "components/_alert-bar.scss";
@import "components/_block.scss";
@import "components/_buttons.scss";
@import "components/_cards.scss";
@import "components/_charity.scss";
@import "components/_claim.scss";
@import "components/_content.scss";
@import "components/_disclosure.scss";
@import "components/_footer.scss";
@import "components/_forms.scss";
@import "components/_header.scss";
@import "components/_headings.scss";
@import "components/_hero.scss";
@import "components/_icons.scss";
@import "components/_image.scss";
@import "components/_insurance.scss";
@import "components/_intro.scss";
@import "components/_jobs.scss";
@import "components/_links.scss";
@import "components/_menu.scss";
@import "components/_services.scss";
@import "components/_slick.scss";
@import "components/_slick.theme.scss";
@import "components/_team.scss";
@import "components/_testimonials.scss";

@import "utilities/_utilities.alignment.scss";
@import "utilities/_utilities.display.scss";

// Import inbox styles
@import 'inbox';
