/**
 * Disclosure Pattern
 *
 */

/* Disclosure Term
   ========================================================================== */

.disclosure__term {

}

/* Disclosure Button
 *
 * 1. Force full width.
 * 2. Reset default button styling
   ========================================================================== */
.disclosure__button {
	position: relative;
	width: 100%; /* [1] */
	border: none; /* [2] */
	background-color: transparent; /* [2] */
	text-align: left; /* [2] */
	cursor: pointer;
}

/* Disclosure: Description
 *
 * 1. Reset vendor properties.
   ========================================================================== */

.disclosure__description {
	margin-left: 0; /* [1] */
}

/* Disclosure: Content
 *
 * 1. Center content in parent.
   ========================================================================== */

.disclosure__content {

}


/* Disclosure FAQ
   ========================================================================== */
.disclosure--faq .disclosure__button {
	position: relative;
	padding-left: 26px;
	font-size: 1rem;
	font-weight: bold;
	color: #272626;
	line-height: 1.625;
}

.disclosure--faq {
	//border-top: 1px solid $color-neutral-50;

	&:first-child {
		border: none;
	}

}

.disclosure--faq .disclosure__button:hover,
.disclosure--faq .disclosure__button:focus {
	//background-color: rgba(53, 100, 146, 0.1);
}

.disclosure--faq .disclosure__button:active {
	//background-color: rgba(53, 100, 146, 0.2);
}

.disclosure--faq .disclosure__button[aria-expanded='true'] {
	//color: $color-white;
}

.disclosure--faq .disclosure__button::before {
	position: absolute;
	left: 0;
	width: 26px;
	height: 26px;
}

.disclosure--faq .disclosure__button[aria-expanded='true']::before {
	content: url('../svg/disclosure_open.svg');
}

.disclosure--faq .disclosure__button[aria-expanded='false']::before {
	content: url('../svg/disclosure_closed.svg');
}

.disclosure--faq .disclosure__description {
	font-size: 0.875rem;
	line-height: 1.5714;
}

.disclosure--faq .disclosure__content {
	padding: 13px 26px;
}
