.c-alert-bar {
	display: flex;
	flex-direction: row;
	justify-content: center;
	height: auto;
	width: 100%;
	z-index: 1001;
	color: #272626;
	padding: 0.5rem 1rem;
	font-size: 1rem;
	background-color: var(--color-sunrise);
}

.c-alert-bar__close {
	flex: 0 0 auto;
}

.c-alert-bar__content p {
	margin-top: 0;
	margin-bottom: 0;
}

.c-alert-bar__content a {
	text-decoration: underline;
}

.c-alert-bar--alert {
	background: #F24D2C;
	color: #ffffff;
}

.c-alert-bar--alert a {
	color: white;
}

.c-alert-bar--warning {
	background: var(--color-sunrise);
}

.c-alert-bar--information {
	background: var(--color-iris);
}

.c-alert-bar--close {
	background-color: transparent;
	border: none;
}

.c-alert-bar--close:hover {
	cursor: pointer;
}

.c-alert-closed {
	max-height: 0;
	overflow: hidden;
	display: none;
}

.c-alert-bar .c-icon--close {
	margin-right: 0;
	margin-left: 0;
	width: 26px;
	height: 26px;
}
