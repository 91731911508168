// Classes to align things

.u-text-center {
	text-align: center !important;
}

.u-align-center {
  margin-left: auto;
  margin-right: auto;
}

.u-align-left {
	text-align: left;
}

.u-align-middle {
	display: flex;
	justify-content: center;
	flex-direction: column;
}
