.c-title-block {
	position: relative;
	padding-top: 26px;
	padding-bottom: 26px;
	border-top: $border-width-thin solid hsl(0, 0%, 89%)
}

@media ( min-width: 900px ) {
	.c-title-block {
		padding-top: 52px;
		padding-bottom: 52px;
	}
}

.c-title-block .c-title--primary + p {
	margin-top: 0;
}


.c-block__header {
	padding-right: 13px;
	padding-left: 13px;
}

@media ( min-width: 900px ) {
	.c-block__header {
		padding-right: 39px;
		padding-left: 39px;
	}
}

/* Block: Video
   ========================================================================== */
.c-block--video {
	position: relative;
	padding-top: 26px;
	padding-bottom: 26px;
}

@media ( min-width: 900px ) {
	.c-block--video {
		padding-top: 39px;
		padding-bottom: 39px;
	}
}

.c-block--video .c-video div::before {
	content: '';
	margin-right: auto;
	margin-left: auto;
	display: block;
	width: calc( 90% + 16px);
	height: 81px;
	border-radius: 6px;
	background-color: #E3E3E3;
	position: absolute;
	bottom: 16px;
	left: 0;
	right: 0;
	z-index: -10;
}

@media ( min-width: 600px ) {
	.c-block--video .c-video div::before {
		width: calc( 70% + 16px);
		max-width: calc( 500px + 16px);
		height: 81px;
	}
}

@media ( min-width: 900px ) {
	.c-block--video .c-video div::before {
		bottom: 26px;
		width: calc( 80% + 26px);
		max-width: calc( 666px + 26px);
		height: 81px;
	}
}

/* Block: Enquiry
   ========================================================================== */
.c-block--enquiry {
	padding-top: 39px;
	padding-bottom: 39px;
}

@media ( min-width: 900px ) {
	.c-block--enquiry {
		padding-top: 52px;
		padding-bottom: 52px;
	}
}

.c-block--enquiry .c-block__content {
	margin-bottom: 26px;
	font-size: 1rem;
	font-weight: normal;
}

.c-block--enquiry .c-highlight {
	margin-top: 0;
	margin-bottom: 0;
	font-size: 1.375rem;
	font-weight: normal;
	line-height: 1.1818;
}

.c-block--enquiry .c-highlight:last-of-type {
	margin-bottom: 13px;
}

.c-block--enquiry .c-highlight a {
	color: #272626;
	font-weight: normal;
}

.c-block--enquiry .c-highlight a:hover,
.c-block--enquiry .c-highlight a:focus {
	color: #3c1f51;
	text-decoration: underline;
}

/* Block: Client List
   ========================================================================== */
.c-block--client-list {
	padding-top: 39px;
	padding-bottom: 39px;
}

.c-block--client-list .c-block__header {
	margin-bottom: 26px;
}

.c-block--client-list .o-gallery__item {
	flex: 0 0 33.333%;
	margin-bottom: 13px;
}


@media ( min-width: 600px ) {
	.c-block--client-list .o-gallery__item {
		flex: 0 0 33.333%;
	}
}

@media ( min-width: 900px ) {
	.c-block--client-list .o-gallery__item {
		flex: 0 0 20%;
	}
}

@media ( min-width: 1200px ) {
	.c-block--client-list .o-gallery__item {
		flex: 0 0 14.2857%;
	}
}

/* Testimonials
   ========================================================================== */

.c-block--testimonials {
	position: relative;
	margin-top: 26px;
	padding-bottom: 26px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

}

.c-block--testimonials .c-block__header {
	position: relative;
	padding-top: 52px;
	padding-bottom: 26px;
	text-align: center;
}

.c-block--testimonials .c-block__header::before {
	content: "";
	position: absolute;
	top: -26px;
	left: 50%;
	left: calc( 50% - 2pt );
	width: $border-width-thick;
	height: 52px;
	background-color: var(--color-canary);
}

@media ( min-width: 1200px ) {
	.t-neutral.c-block--testimonials::after {
		content: '';
		position: absolute;
		top: 0;
		right: -41px;
		display: block;
		width: 82px;
		height: 356px;
		background-image: url( '/dist/images/side-pattern.png' );
		background-repeat: no-repeat;
	}
}
.t-dark.c-block--testimonials {
	background-color: #3c1f51;
	background-image: url( '../svg/testimonials-background.svg' );
	color: #fff;
}

/* Slider
   === */
.c-block--testimonials .slick-dots {
	margin-top: 26px;
}

/* Block: Contact
   ========================================================================== */
.c-block--contact {
	margin-top: 13px;
	margin-bottom: 13px;
	padding-top: 13px;
	padding-right: 13px;
	padding-bottom: 13px;
	padding-left: 13px;
}

@media ( min-width: 900px ) {
	.c-block--contact {
		columns: 3;
		column-rule: $border-width-thin solid #e3e3e3;
		column-gap: 39px;
	}
}

.c-block--contact .c-card {
	margin-bottom: 26px;
	text-align: center;
	break-inside: avoid;
	page-break-inside: avoid;
}

@media ( min-width: 900px ) {
	.c-block--contact .c-card {
		flex: 1 1 30%;
		margin-bottom: 0;
	}
}

.c-block--contact .c-card__header .c-icon {
	margin-bottom: 26px;
}

.c-block--contact .tel {
	display: block;
	font-weight: bold;
	color: #333333;
}

.c-block--contact .c-icon--phone {
	color: var(--color-canary);
}

.c-block--contact .link--primary {
	display: block;
	font-weight: bold;
}

.c-block--contact .link--primary:active,
.c-block--contact .link--primary:link,
.c-block--contact .link--primary:visited {
	text-transform: initial;
	font-size: 1rem;
	font-weight: $font-weight-bold;
}

/* Block: Split
   ========================================================================== */

.c-block__split {
	padding-top: 13px;
	padding-bottom: 13px;
	border-top: 1pt solid #d8d8d8;
}

@media ( min-width: 900px ) {
	.c-block__split {
		padding-top: 26px;
		padding-bottom: 26px;
		column-count: 2;
		column-gap: 26px;
	}
}

@media ( min-width: 1200px ) {
	.c-block__split {
		padding-top: 39px;
		padding-bottom: 39px;
		column-gap: 39px;
	}
}

@media ( min-width: 900px ) {
	.c-block__split .c-testimonial {
		break-inside: avoid;
		page-break-inside: avoid;
	}

}

.c-block__split .c-block__content {
	margin-bottom: 26px;
	padding-right: 13px;
	padding-left: 13px;
}

@media ( min-width: 900px ) {
	.c-block__split .c-block__content {
		margin-bottom: 0;
	}
}

@media ( min-width: 900px ) {

	.c-block__split .c-testimonial {
		break-inside: avoid;
		page-break-inside: avoid;
	}

}

/* Block: Toolbar
   ========================================================================== */
.c-block--toolbar {
	margin-top: 26px;
	margin-bottom: 26px;
}

@media ( min-width: 600px ) {
	.c-block--toolbar {
		margin-top: 39px;
		margin-bottom: 39px;
	}
}

.t-neutral.c-block--testimonials .slick-dots li button::before {
	border-color: #767676;
	background-color: #767676;
}

.t-neutral.c-block--testimonials .slick-dots li.slick-active button::before {
	border-color: var(--color-canary);
	background-color: var(--color-canary);
}


/* Block: CV
   ========================================================================== */

.c-block--cv {
	border-top: $border-width-thin solid hsl(0, 0%, 89%);
	padding: 26px;
}

.c-block--cv p {
	font-weight: 400;
}
