.c-menu {
	display: flex;
	justify-content: space-between;
   padding: 60px 100px;
	flex-direction: column;
	padding: 26px;
	background-blend-mode: multiply;
	background-color: hsla(0, 1%, 15%, 0.85);
}

@media ( min-width: 900px ) {
	.c-menu {
		padding: 52px;
	}
}

@media ( min-width: 1200px ) {
	.c-menu {
		padding: 60px 100px;
	}
}

.c-menu a,
.c-menu a:active,
.c-menu .c-icon {
	color: white;
	fill: white;
}

.c-menu a:visited,
.c-menu .c-icon {
	color: white;
	fill: white;
}

.c-menu .current-menu-item a,
.c-menu a:hover,
.c-menu a:focus,
.c-menu a:active,
.c-menu a:hover .c-icon,
.c-menu a:focus .c-icon,
.c-menu a:active .c-icon {
	color: var(--color-canary);
	fill: var(--color-canary);
	text-decoration: none;
}

.c-menu .c-icon--close {
	width: 26px;
	height: 26px;
	text-decoration: none;
}

/**
 * 1. Hide our menu off the viewport.
 * 2. Display the menu within the viewport.
 */

.js .c-menu {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 600px;
	z-index: $z-index-8;
	overflow: scroll;
	transform: translate3d(-100.5%, 0, 0); /* [1] */
	transition-duration: $transitions-duration;
	transition-property: transform;
	transition-timing-function: $transitions-timing;

	&.is-active {
		transform: translate3d(0, 0, 0); /* [2] */
	}

	&.c-menu--right {
		right: 0;
		left: initial;
		width: 100%;
		transform: translate3d(100.5%,0,0); /* [1] */

		/*@include for-medium-portrait-up {
			width: 600px;
		}*/

		// @include for-medium-landscape-up {
		// 	width: 50%;
		// }

		&.is-active {
			transform: translate3d(0, 0, 0); /* [2] */
		}

	}

}

/**
 * 1. Center navigation in parent element.
 * 2. Allow buttons to work like a group of buttons.
 * 3. Fix central allinement in Safari.
 */
.c-menu__nav {
	margin-right:  auto; // [1]
	margin-bottom: 1.5rem;
	margin-left: auto; // [1]
	text-align: center; // [3]

	& .c-btn {
		margin-right: 0;
		margin-left: 0;
	}

	& .c-btn:first-child {
		border-top-right-radius: 0; // [2]
		border-bottom-right-radius: 0; // [2]
	}

	& .c-btn:last-child {
		border-top-left-radius: 0; // [2]
		border-bottom-left-radius: 0; // [2]
	}

}

.c-menu__controls {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	margin-bottom: 26px;
}

.c-menu__content {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.c-menu__content .c-social-media {
	order: 2;
	align-self: end;
}

.c-menu__content .c-social-media .c-icon {
	color: var(--color-canary);
	vertical-align: middle;
}

.c-menu__content .c-social-media .c-icon:hover,
.c-menu__content .c-social-media .c-icon:focus {
	color: hsl(0, 0%, 100%);
}

.c-menu__content .c-details {
	order: 1;
}

.c-menu__content .o-list__item:not( :first-of-type ) {
	margin-left: 13px;
}

.c-menu__content .phone {
	font-weight: $font-weight-bold;
}

.c-menu__content .c-icon--phone {
	width: 17px;
	height: 26px;
	color: var(--color-canary);
	vertical-align: middle;
}

.c-menu__content .email a {
	font-weight: $font-weight-bold;
	color: var(--color-canary);
}

.c-menu__content .email a:hover,
.c-menu__content .email a:focus {
	color: hsl(0, 0%, 100%);
}

.c-menu__footer {
	margin-right: auto;
	margin-left: auto;
	text-align: center;
}

/* Primary Navigation
   ========================================================================== */

.c-navigation--primary {
	margin-bottom: 78px;
	font-size: 1.625rem;
	font-weight: $font-weight-bold;
	line-height: 1.0384;
}

@media ( min-height: 600px ) {
	.c-navigation--primary {
		margin-bottom: 130px;
	}
}

.c-navigation--primary {
	margin-bottom: 78px;
	font-size: 1.625rem;
	font-weight: $font-weight-bold;
	line-height: 1.0384;
}

.c-navigation--primary .menu-item:not( :first-child ) {
	margin-top: 26px;
}

@media ( min-width: 900px ) {
	.c-navigation--primary .menu-item:not( :first-child ) {
		margin-top: 39px;
	}
}
/* Footer Navigation
 *
 * 1. Center elements at small screens.
   ========================================================================== */

/*@include for-small-screen-only {

	.c-navigation--footer {
		flex-direction: column; // [1]
		text-align: center; // [1]
	}

}*/

/* Social Media
   ========================================================================== */

.c-nav--social {
	margin-bottom: 16px;

/*	@include for-medium-landscape-up {
		margin-right: 16px;
	}*/

	.c-icon {
		width: 1.875rem;
		height: 1.875rem;

/*		@include for-medium-landscape-up {
			width: 1.375rem;
			height: 1.375rem;
		}*/

	}

	a:active .c-icon {
		transition-duration: $transitions-duration;
		transition-property: fill;
		transition-timing-function: $transitions-timing;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
	}

}

.c-nav--social__item {
	margin-right: 28px;

/*	@include for-medium-landscape-up {
		margin-right: 16px;
	}*/

	&:last-child {
		/* Remove margin to align flush to the right with other elements. */
		margin-right: 0;
	}

}

.c-menu__content .c-nav--social__item {
	margin-right: 13px;

/*	@include for-medium-landscape-up {
		margin-right: 9px;
	}*/

}

/* Site Overlay
   ========================================================================== */

.c-menu-overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 100%;
	left: 0;
//	background-blend-mode: multiply;
//	background-color: hsla(0, 1%, 15%, 0.75);
	z-index: $z-index-7;
	transition-duration: $transitions-duration;
	transition-property: background-color;
	transition-timing-function: $transitions-timing;

	&.is-active {
		bottom: 0;
//		background-blend-mode: multiply;
//		background-color: hsla(0, 1%, 15%, 0.75);
	}

}
