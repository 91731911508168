
@media ( min-width: 900px ) {
	.form__row {

		grid-gap: 26px;
	}
}

@media ( min-width: 1200px ) {
	.form__row {
		grid-gap: 39px;
	}
}

@media ( min-width: 900px ) {
	.form__row--2 {
		display: grid;
		grid-template-columns: repeat( 2, 0.5fr );
	}
	.form__row--3 {
		display: grid;
		grid-template-columns: repeat( 3, 0.3333fr );
	}
}

/* IE Grid Fixes
   ========================================================================== */

@media ( min-width: 900px ) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.form__row--2 .form__group:nth-child(2) {
		grid-column: 2;
	}
}

@media ( min-width: 900px ) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.form__row--3 .form__group:nth-child(2) {
		grid-column: 2;
	}
}

@media ( min-width: 900px ) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.form__row--3 .form__group:nth-child(3) {
		grid-column: 3;
	}
}

@media ( min-width: 900px ) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.form__row .form__group, {
		margin-right: 13px;
		margin-left: 13px;
	}
}

@media ( min-width: 1200px ) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.form__row .form__group {
		margin-right: 19.5px;
		margin-left: 19.5px;
	}
}

/* Form: Groups
   ========================================================================== */

.form__group {
	margin-bottom: 13px;
}

.form__group--checkbox {
	display: flex;
	flex-direction: row;
	font-size: 0.75rem;
	line-height: 1.0833;
}

.form__group--as {
	position: absolute;
	top: -9999px;
	left: -9999px;
}

/* Form Elements
   ========================================================================== */

.form__description {
	margin-bottom: 26px;
}

.form__fieldset {
	border: 0;
}

.form__fieldset .form__legend {
	margin-bottom: 13px;
}


#insurance .form__group {
	margin-bottom: 20px;
}

.form__label {
	display: block;
	cursor: pointer;
}

.form__label_half {
	display: flex;
	flex-wrap:wrap;
	margin-bottom:10px;

	label{
		flex: 1 1 46%;
		margin-right:4%;

		@media(max-width: 480px){
			flex:1 1 100%;
			margin-right:0;
		}

		&:last-child{
			margin-right:0;
		}
	}
}


.form__row--split_three{
	display: flex;
	flex-wrap:wrap;


	.form__row--checkbox{
		flex:1 1 50%;

		@media(max-width: 480px){
			flex:1 1 100%;
		}
	}

   .form__row--checkbox>input {
	    width: 15px;
	    height: 15px;
 	}
 	.form__label--checkbox {
	    display: inline-block;
	    margin: 0 10px 0 0;
	}
}

.form__input,
.form__textarea {
	padding: 6.5px 13px;
	width: 100%;
	border: $border-width-thin solid hsl(0, 0%, 78%);
	border-radius: 3px;
}

.form__input {
	line-height: 1.625;
}

.form__textarea {
	line-height: 1.625;
}

.form__checkbox {
	margin-right: 6.5px;
	align-self: baseline;
	cursor: pointer;
}

/* Form Message and Errors
   ========================================================================== */

.form__required {
	color: red;
}

.form__input--has-error {
	border-color: red;
	outline-color: red;
}

.form__message {
	font-size: 0.85em;
}

.form__message--error {
	color: red;
	margin-bottom: 5px;
}

.form__notification {
	position: relative;
	padding: .75rem 1.25rem;
	margin-bottom: 1rem;
	border: 1px solid transparent;
	border-radius: 3px;
}

.form__notification--success {
	color: white;
	background-color: green;
	border-color: darken( green, 25% );
}

.form__notification--error {
	color: white;
	background-color: red;
	border-color: darken( red, 25% );
}

/* Form: Sidebar
   ====== */
.c-form--sidebar {
	padding: 26px;
	border-radius: 6px;
	background-color: var(--color-petrol);
	color: #fff;
}

.c-form--sidebar .c-title {
	margin-bottom: 13px;
	color: var(--color-canary);
}

.c-form .form__group--submit {
	margin-top: 26px;
	margin-bottom: 0;
	text-align: center;
}

.c-form--sidebar .btn--primary {
	width: 75%;
}

.t-dark .form__label {
	color: #FFF;
}

.t-dark .form__group--checkbox .form__label {
	color: hsl(0, 0%, 100%);
}
